<template>
  <v-card v-if="property.id">
    <v-tabs
      v-model="tab"
      centered
    >
      <v-tab>
        Náhled
      </v-tab>
      <v-tab>
        Formulář
      </v-tab>
      <v-tab>
        Nastavení ceny
      </v-tab>
      <v-tab>
        Recenze
      </v-tab>
      <v-tab>
        Statistiky
      </v-tab>
      <v-tab v-if="property.approvalProcessState === 3">
        Inzerce
      </v-tab>
      <v-tab v-if="property.advertising.length">
        Topování
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <OverviewDetail/>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <EditPropertyStepperWrapper :property-owner-id="property.owner.id"/>
      </v-tab-item>
      <v-tab-item>
        <PropertyPriceSettings></PropertyPriceSettings>
      </v-tab-item>
      <v-tab-item>
        <ReviewsOverview type="property"/>
      </v-tab-item>
      <v-tab-item>
        <PropertyViewsChart></PropertyViewsChart>
      </v-tab-item>
      <v-tab-item v-if="property.approvalProcessState === 3">
        <AdvertisingSettings />
      </v-tab-item>
      <v-tab-item v-if="property.advertising.length">
        <PromoSettings />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
  <v-card v-else>
    <v-skeleton-loader type="image"/>
  </v-card>
</template>

<script>
import PropertyViewsChart from '../../components/charts/PropertyViewsChart'
import PropertyPriceSettings from '../../components/price/PropertyPriceSettings'
import OverviewDetail from '@/components/property/detail/OverviewDetail'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EditPropertyStepperWrapper from '@/components/property/EditPropertyStepperWrapper'
import ReviewsOverview from '@/components/users/ReviewsOverview'
import AdvertisingSettings from '@/components/advertising/AdvertisingSettings'
import PromoSettings from '@/components/promo/PromoSettings'

export default {
  name: 'CardDetail',
  components: {
    PromoSettings,
    AdvertisingSettings,
    ReviewsOverview,
    EditPropertyStepperWrapper,
    OverviewDetail,
    PropertyPriceSettings,
    PropertyViewsChart
  },
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    ...mapGetters({
      property: 'detailViews/property'
    })
  },
  methods: {
    ...mapMutations({
      setProperty: 'detailViews/setProperty'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    }),
    loadData () {
      this.$http.get(`/admin/properties/${this.$route.params.id}`)
        .then(res => {
          if (res.data.redirect) {
            this.$router.replace({
              name: 'admin.properties.property',
              params: {
                id: res.data.redirect
              }
            })
          }
          this.setProperty(res.data)
        })
        .catch(err => {
          if (err.response.status === 400) {
            this.setAlerts([])
            this.$router.push({
              name: 'admin.properties.property',
              params: { id: err.response.data.redirect }
            })
          }
          if (err.response.status === 404) {
            this.setAlerts([{
              message: 'Objekt nenalezen',
              type: 'error'
            }])
          }
        })
    }
  },
  created () {
    this.loadData()
    this.$http.get('/admin/global-advertising-settings')
      .then(res => {
        this.$store.state.detailViews.globalAdvertisingSettings = res.data
      })
  },
  beforeDestroy () {
    this.setProperty({})
  },
  watch: {
    /**
     * Checkuje zmenu id v url
     * Vue pouziva stale stejnou instanci komponenty, i kdyz se zmeni parametry routy (pres $router.push)
     * @return void
     */
    '$route.params': function () {
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
