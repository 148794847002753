<template>
  <div v-if="Object.keys(property).length">
    <v-row>
      <v-col id="property-info">
        <div id="property-top-detail">
          <div class="text-center d-flex" id="property-name">
            <span class="property-title mr-4">{{property.name}}</span>
          </div>
          <div id="property-quick-info">
          <span class="property-reviews d-flex">
            <span class="property-rating-value align-self-center">
                    ({{property.rating}})
                  </span>
                  <v-rating
                    readonly
                    color="orange"
                    half-increments
                    :value="+property.rating"
                    length="5"
                    size="22"
                  >
                  </v-rating>
          </span>
            <div class="d-flex" id="property-place">
              <v-icon color="primary">mdi-map-marker</v-icon>
              <span>{{property.localityDisplayName}}</span>
            </div>
          </div>
        </div>
        <div id="property-description" class="mt-3">
          <span class="custom-heading">Popis</span>
          <div v-html="property.description">

          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <Gallery
          :photo-paths="property.propertyPhotos.map(photo => photo.photoPath)"
          :main-photo-path="property.miniPhotoPath"
        ></Gallery>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="custom-heading">Vybavení</div>
        <div class="d-flex equipment-list">
          <span
            class="equipment-check align-center d-flex mr-2 ml-2"
            v-for="(equipment, index) in equipmentOverview"
            :key="index"
          >
            <!-- TODO: poresit vzdalenosti -->
            <v-icon color="green" class="mr-1">mdi-check</v-icon>
            {{equipment.name}}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="custom-heading">Pokoje</div>
        <table id="room-table">
          <thead>
          <th>Název</th>
          <th>Kapacita</th>
          <th>Vybavení</th>
          </thead>
          <tbody>
          <tr v-for="(room, index) in property.rooms" :key="index">
            <td>{{room.name}}</td>
            <td>{{ room.capacity }} osob{{
                room.capacity === 1 ? 'a'
                  : (room.capacity > 5) ? ''
                    : 'y'
              }}</td>
            <td>
              <div class="d-flex equipment-list">
                <span class="equipment-check align-center d-flex mr-2 ml-2"
                      v-for="(eq, i) in JSON.parse(room.equipment)" :key="i"
                >
                  <v-icon color="green" class="mr-1">
                  mdi-check</v-icon>
                  {{ eq.name }}
                </span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
  <v-skeleton-loader type="card" v-else />
</template>

<script>
import Gallery from './Gallery'
import { mapActions, mapGetters } from 'vuex'

// TODO: zeptat se na parametry:
//        jazyk hostitele, hvezdicky, kontaktni osoba, check-in
export default {
  name: 'OverviewDetail',
  components: { Gallery },
  data () {
    return {
      propertyDetail: null
    }
  },
  computed: {
    equipmentOverview () {
      const finalObj = {}
      /**
       * @var this.property {
       *   rooms: Array
       * }
       */
      this.property.rooms.forEach(room => {
        if (room.equipment) {
          const roomEquipment = JSON.parse(room.equipment)
          Object.keys(roomEquipment)
            .forEach(equipmentTypeId => {
              if (!(equipmentTypeId in finalObj)) {
                finalObj[equipmentTypeId] = roomEquipment[equipmentTypeId]
              }
            })
        }
      })
      return finalObj
    },
    ...mapGetters({
      property: 'detailViews/property'
    })
  },
  methods: {
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  }
}
</script>

<style scoped>
#property-name{
  align-items: center;
  justify-content: space-between;
}
#property-top-detail{
  background-color: #ffd993;
  padding: 20px 12px;
}
.property-title{
  font-size: 28px;
}
.equipment-check{
  color: green
}

#room-table{
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
#room-table td, #room-table th{
  border: 1px solid #ddd;
  padding: 8px;
}
#room-table tr:nth-child(even){background-color: #f2f2f2;}
</style>
